export enum ComparisonPeriod {
  YoY = 'YoY',
  MoM = 'MoM',
}

export const CovarianceBrand = {
  id: 'covariance',
  name: 'Covariance',
  imageName: 'Covariance',
  color: 'white',
};

export const DEFAULT_BENCHMARK_SLUG = 'all-competitors';

export const EASTERN_TIME_ZONE = 'America/New_York';

export const SUMMARY_REGION_NAME = 'all regions';

export enum TooltipName {
  // NOTE: remove related Tooltip table entries
  //   whenever removing from the list

  Acquisition_AcquisitionGrowth = 'Acquisition_AcquisitionGrowth',
  Acquisition_AcquisitionAndReactivationHistory = 'Acquisition_AcquisitionAndReactivationHistory',
  Acquisition_ConquestedCustomerQuality = 'Acquisition_ConquestedCustomerQuality',
  Acquisition_ConquestingRatio = 'Acquisition_ConquestingRatio',

  Journey_KeyTrips_Loosing_Trips = 'Journey_KeyTrips_Loosing_Trips',
  Journey_KeyTrips_Loosing_WalletShare = 'Journey_KeyTrips_Loosing_WalletShare',
  Journey_KeyTrips_Winning_Trips = 'Journey_KeyTrips_Winning_Trips',
  Journey_KeyTrips_Winning_WalletShare = 'Journey_KeyTrips_Winning_WalletShare',

  Journey_ShoppingTrips_Before_Percentage = 'Journey_ShoppingTrips_Before_Percentage',
  Journey_ShoppingTrips_Before_AverageGap = 'Journey_ShoppingTrips_Before_AverageGap',
  Journey_ShoppingTrips_After_Percentage = 'Journey_ShoppingTrips_After_Percentage',
  Journey_ShoppingTrips_After_AverageGap = 'Journey_ShoppingTrips_After_AverageGap',

  Loyalty_Frequency_Total_Brand = 'Loyalty_Frequency_Total_Brand',
  Loyalty_Frequency_Total_Benchmark = 'Loyalty_Frequency_Total_Benchmark',

  Loyalty_Frequency_ByDayOfWeek_Weekday_Brand = 'Loyalty_Frequency_ByDayOfWeek_Weekday_Brand',
  Loyalty_Frequency_ByDayOfWeek_Weekday_Benchmark = 'Loyalty_Frequency_ByDayOfWeek_Weekday_Benchmark',

  Loyalty_Frequency_ByDayOfWeek_Weekend_Brand = 'Loyalty_Frequency_ByDayOfWeek_Weekend_Brand',
  Loyalty_Frequency_ByDayOfWeek_Weekend_Benchmark = 'Loyalty_Frequency_ByDayOfWeek_Weekend_Benchmark',

  Loyalty_Frequency_ByDayOfWeek_Total_Brand = 'Loyalty_Frequency_ByDayOfWeek_Total_Brand',
  Loyalty_Frequency_ByDayOfWeek_Total_Benchmark = 'Loyalty_Frequency_ByDayOfWeek_Total_Benchmark',

  Loyalty_Frequency_ByAverageTicket_Segment1_Brand = 'Loyalty_Frequency_ByAverageTicket_Segment1_Brand',
  Loyalty_Frequency_ByAverageTicket_Segment1_Benchmark = 'Loyalty_Frequency_ByAverageTicket_Segment1_Benchmark',

  Loyalty_Frequency_ByAverageTicket_Segment2_Brand = 'Loyalty_Frequency_ByAverageTicket_Segment2_Brand',
  Loyalty_Frequency_ByAverageTicket_Segment2_Benchmark = 'Loyalty_Frequency_ByAverageTicket_Segment2_Benchmark',

  Loyalty_Frequency_ByRepeatCustomers_Brand = 'Loyalty_Frequency_ByRepeatCustomers_Brand',
  Loyalty_Frequency_ByRepeatCustomers_Benchmark = 'Loyalty_Frequency_ByRepeatCustomers_Benchmark',

  OverviewMapTitle = 'OverviewMapTitle',

  Overview_HistoryChart_SalesShare = 'Overview_HistoryChart_SalesShare',
  Overview_HistoryChart_MarketSize = 'Overview_HistoryChart_MarketSize',
  Overview_HistoryChart_TotalUnits = 'Overview_HistoryChart_TotalUnits',
  Overview_HistoryChart_UnitShare = 'Overview_HistoryChart_UnitShare',

  Overview_KeyMetrics_CompSales = 'Overview_KeyMetrics_CompSales',
  Overview_KeyMetrics_GrossSalesShare = 'Overview_KeyMetrics_GrossSalesShare',
  Overview_KeyMetrics_GrossSales = 'Overview_KeyMetrics_GrossSales',
  Overview_KeyMetrics_TTMFrequency = 'Overview_KeyMetrics_TTMFrequency',
  Overview_KeyMetrics_UnitShare = 'Overview_KeyMetrics_UnitShare',
  Overview_KeyMetrics_TotalUnits = 'Overview_KeyMetrics_TotalUnits',
  Overview_KeyMetrics_TTMOpeningsClosings = 'Overview_KeyMetrics_TTMOpeningsClosings',

  Overview_RankTimeSeries_MarketShare = 'Overview_RankTimeSeries_MarketShare',
  Overview_RankTimeSeries_SalesGrowth = 'Overview_RankTimeSeries_SalesGrowth',
  Overview_RankTimeSeries_AverageTicketGrowth = 'Overview_RankTimeSeries_AverageTicketGrowth',
  Overview_RankTimeSeries_AcquisitionGrowth = 'Overview_RankTimeSeries_AcquisitionGrowth',
  Overview_RankTimeSeries_Frequency = 'Overview_RankTimeSeries_Frequency',
  Overview_RankTimeSeries_Units = 'Overview_RankTimeSeries_Units',

  Spendalike_Segments_AudienceSize = 'Spendalike_Segments_AudienceSize',
  Spendalike_Segments_Share = 'Spendalike_Segments_Share',
  Spendalike_Segments_TopCompetitor = 'Spendalike_Segments_TopCompetitor',
  Spendalike_Segments_GrowthImpact = 'Spendalike_Segments_GrowthImpact',

  WalletShare_Competitors_CustomerOverlap = 'WalletShare_Competitors_CustomerOverlap',
  WalletShare_Competitors_WalletShare = 'WalletShare_Competitors_WalletShare',
  WalletShare_FrequencyVsAverageTicket = 'WalletShare_FrequencyVsAverageTicket',
}

export const ALL_COMPETITORS_BENCHMARK_ID = 'all-competitors';

export const LOW_OVERLAP_THRESHOLD = 0.02;
