import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import useSWR from 'swr';

import { formDataToObject } from '@/src/app/helpers/form';
import { getLLMResponse } from '@/src/features/chat/_actions/get-llm-response';
import { ChatMessage, ChatRole } from '@/src/features/chat/types';
import { useChatDataContextStore } from '@/src/features/common/_stores/chat-data-context';

import { getChatHistory } from '../../projects/dashboard/_actions/get-chat-history';
import { CanName } from '../../projects/dashboard/_components/Chat';

type ChatMessageWithPatternKeys = ChatMessage & { patternKeys?: CanName[] };

export const useChat = (
  {
    keyPatterns,
    projectId,
  }: {
    keyPatterns?: Record<CanName, { pattern: RegExp; response: string }>;
    projectId: string | null;
  } = { projectId: null },
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ChatMessageWithPatternKeys[]>([]);

  const chatContextData = useChatDataContextStore((state) => {
    return state.data;
  });

  useSWR(
    ['chat-history', projectId],
    async () => {
      if (!projectId) return;

      return getChatHistory({
        projectId,
      });
    },
    {
      onSuccess: (data) => {
        if (!data) return;

        const messagesWithPatternKeys: ChatMessageWithPatternKeys[] = [];
        let previousMessagePatternKeys: CanName[] = [];

        for (const message of data) {
          if (message.role === ChatRole.USER) {
            messagesWithPatternKeys.push(message);

            if (keyPatterns) {
              previousMessagePatternKeys = (
                Object.keys(keyPatterns) as CanName[]
              ).filter((key) => keyPatterns[key].pattern.test(message.content));
            }
            continue;
          }

          messagesWithPatternKeys.push({
            ...message,
            patternKeys: previousMessagePatternKeys,
          });

          previousMessagePatternKeys = [];
        }

        setMessages(messagesWithPatternKeys);
      },
    },
  );

  const submitMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const values = formDataToObject(new FormData(e.currentTarget));
    const { prompt } = values;
    if (isEmpty(prompt)) return;
    setMessages((prev) => [
      ...prev,
      { role: ChatRole.USER, content: prompt, date: new Date() },
    ]);
    const form = e.target as HTMLFormElement;
    form.reset();

    setIsLoading(true);

    let cannedResponse;
    const matchedPatternKeys: CanName[] = [];
    if (keyPatterns) {
      for (const [key, { pattern, response }] of Object.entries(keyPatterns)) {
        if (pattern.test(prompt)) {
          cannedResponse = response;
          matchedPatternKeys.push(key as CanName);
          break;
        }
      }
    }

    const content = await getLLMResponse({
      contextData: chatContextData,
      messages: [...messages, { role: ChatRole.USER, content: prompt }],
      projectId,
      cannedResponse,
    }).finally(() => setIsLoading(false));

    if (content) {
      setMessages((prev) => [
        ...prev,
        {
          role: ChatRole.ASSISTANT,
          content,
          date: new Date(),
          patternKeys: matchedPatternKeys,
        },
      ]);
    }
  };

  return { isLoading, submitMessage, messages };
};
